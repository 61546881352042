import useAuthRoute from '../../hooks/useAuthRoute'
import { Redirect, Route, useLocation } from 'react-router'
import * as paths from '../paths'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useLDClient } from 'launchdarkly-react-client-sdk'
import useFlaggingAttributes from '../../components/feature-flag/useFlaggingAttributes'
import { putLDHasIdentified } from 'redux/slices/auth'
import { useDispatch } from 'react-redux'

const AuthRoute = (props) => {
  const location = useLocation()

  const { attributes, isAttributesReady } = useFlaggingAttributes()
  const dispatch = useDispatch()
  const ldClient = useLDClient()
  const lastIdentify = useRef(null)

  const {
    member,
    shouldRedirectToLogin,
    shouldRedirectToUnderage,
    shouldRedirectToGraduation,
    shouldRedirectToTerms,
    isRelevantPath,
    shouldRedirectToSSO,
    shouldRedirectDigitalOnlyMember,
  } = useAuthRoute({ ...props, location })

  const identifyUser = useCallback(async () => {
    const stringified = JSON.stringify(attributes)
    if (lastIdentify.current === stringified) {
      lastIdentify.current = stringified
      return
    }
    lastIdentify.current = stringified
    try {
      await ldClient?.identify(attributes)
      dispatch(putLDHasIdentified({ hasIdentified: true }))
    } catch (e) {
      console.log('An error occured when calling ldClient?.identify', e)
    }
  }, [attributes, ldClient])

  useEffect(() => {
    if (isRelevantPath) {
      if (!shouldRedirectToLogin) {
        if (isAttributesReady) {
          identifyUser()
        }
      }
    }
  }, [isRelevantPath, shouldRedirectToLogin, isAttributesReady, attributes, identifyUser])

  if (shouldRedirectToSSO) {
    return (
      <Redirect
        to={{
          ...location,
          pathname: paths.WEB_SSO,
          state: { referrer: location.pathname },
        }}
      />
    )
  }

  if (shouldRedirectToLogin) {
    return (
      <Redirect
        to={{
          pathname: paths.LOGIN,
          state: { referrer: location.pathname },
        }}
      />
    )
  }
  if (shouldRedirectToUnderage) {
    return (
      <Redirect
        to={{
          pathname: paths.UNDERAGE,
          state: { referrer: location.pathname },
        }}
      />
    )
  }

  if (shouldRedirectToGraduation) {
    return (
      <Redirect
        to={{
          pathname: paths.GRADUATION,
          state: { referrer: location.pathname },
        }}
      />
    )
  }

  if (shouldRedirectToTerms) {
    return (
      <Redirect
        to={{
          pathname: paths.TERMS_AND_CONDITIONS,
          state: { referrer: location.pathname },
        }}
      />
    )
  }

  if (shouldRedirectDigitalOnlyMember) {
    return (
      <Redirect
        to={{
          pathname: paths.DASHBOARD,
          state: { referrer: location.pathname },
        }}
      />
    )
  }

  return <Route {...props} member={member} />
}

export default AuthRoute
