import { ReactNode } from 'react'

type ContactSupportSceneProps = {
  children: ReactNode
}

export const ContactSupportScene = ({ children }: ContactSupportSceneProps) => {
  return <div className="flex flex-col items-start sm:gap-2">{children}</div>
}

type ContactSupportImageProps = {
  children: ReactNode
}

export const ContactSupportImage = ({ children }: ContactSupportImageProps) => {
  return (
    <div className="static bottom-0 right-0 flex items-start justify-start sm:top-0 sm:max-w-[345px] md:right-auto md:max-w-[560px] md:shrink-0 lg:max-w-[680px] xl:w-3/5">
      {children}
    </div>
  )
}