const Scenes = {
  login: {
    email: 'email',
    password: 'password',
    expiredPassword: 'expiredPassword',
    accessCode: 'accessCode',
    generateMfa: 'generateMfa',
    verifyMfa: 'verifyMfa',
    sso: 'sso',
    underage: 'underage',
    dashboard: 'dashboard',
    forgotPassword: 'forgotPassword',
    createAccount: 'createAccount',
  },
  registration: {
    loading: 'loading',
    eligibility: 'eligibility',
    memberMain: 'memberMain',
    memberContacts: 'memberContacts',
    account: 'account',
  },
  medicalWizard: {
    getStarted: 'get-started',
    basicInfo: 'basic-info',
    lifestyle: 'lifestyle',
    medicalHistory: 'medical-history',
    medications: 'medications',
    allergies: 'allergies',
    familyHistory: 'family-history',
    physicians: 'physicians',
    profileComplete: 'profile-complete',
  },
  newVisit: {
    engineEntry: 'engineEntry',
    addService: 'addService',
    memberSelect: 'memberSelect',
    location: 'location',
    locationCountry: 'locationCountry',
    locationState: 'locationState',
    visitType: 'visitType',
    reason: 'reason',
    preference: 'preference',
    modality: 'modality',
    providerList: 'providerList',
    scheduling: 'scheduling',
    pharmacy: 'pharmacy',
    payment: 'payment',
    confirmation: 'confirmation',
    noAvailableProviders: 'noAvailableProviders',
    visitQuestions: 'visitQuestions',
    recommended: 'recommended',
    callSupport: 'callSupport',
  },
} as const

type DistributiveValues<T extends Record<string, any>> = T extends T ? T[keyof T] : never
type InnerValues<T extends Record<keyof T, object>, K extends keyof T> = DistributiveValues<T[K]>
export type SceneValues = InnerValues<typeof Scenes, keyof typeof Scenes>

export default Scenes
