// Temporary file
export const mockedServices = [
    {
        "serviceId": "2D54B5A4CABC48179D1CE89118D03802",
        "accessCode": null,
        "limitGroup": 100,
        "limitMember": 1,
        "serviceType": "psychiatry",
        "utilityType": "clinical",
        "serviceFee": 22,
        "limitAnniversaryAt": "12-30",
        "hardStop": true,
        "overLimitFee": 0,
        "status": "conditionally_enabled"
    },
    {
        "serviceId": "1FECCB3D15DE45E78B4C288FC54F632F",
        "accessCode": null,
        "limitGroup": 100,
        "limitMember": null,
        "serviceType": "medical",
        "utilityType": "clinical",
        "serviceFee": 8,
        "limitAnniversaryAt": "09-11",
        "hardStop": false,
        "overLimitFee": 0,
        "status": "disabled"
    },
    {
        "serviceId": "5DBA985924E14092871B3AD17F404FC6",
        "accessCode": null,
        "limitGroup": 100,
        "limitMember": null,
        "serviceType": "hc",
        "utilityType": "clinical",
        "serviceFee": 0,
        "limitAnniversaryAt": "09-11",
        "hardStop": false,
        "overLimitFee": 0,
        "status": "conditionally_enabled"
    },
    {
        "serviceId": "24697AC960124BF49239D8ED1DAC0D20",
        "accessCode": null,
        "limitGroup": 100,
        "limitMember": 12,
        "serviceType": "therapy",
        "utilityType": "clinical",
        "serviceFee": 0,
        "limitAnniversaryAt": "09-11",
        "hardStop": false,
        "overLimitFee": 0,
        "status": "conditionally_enabled"
    },
    {
        "serviceId": "E3C488299AC34CCF838E98042330B934",
        "accessCode": null,
        "limitGroup": null,
        "limitMember": 1,
        "serviceType": "medical_now",
        "utilityType": "clinical",
        "serviceFee": 0,
        "limitAnniversaryAt": "12-30",
        "hardStop": false,
        "overLimitFee": 0,
        "status": "hidden"
    },
    {
        "serviceId": "414011E0BDA944C29B9BFFCC7DFF4CA6",
        "accessCode": null,
        "limitGroup": null,
        "limitMember": null,
        "serviceType": "therapy_now",
        "utilityType": "clinical",
        "serviceFee": 20,
        "limitAnniversaryAt": "12-31",
        "hardStop": false,
        "overLimitFee": 0,
        "status": "conditionally_enabled"
    }
]