import { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { omit } from 'lodash'
import { useHistory } from 'react-router'

import Scenes from '../../../util/Scenes'
import NewVisitLocationForm from '../../../forms/visit-creation/NewVisitLocationForm'
import { EVisitModalities } from '../../../util/visit-modalities'
import NewVisitLocationSchema from '../../../schemas/NewVisitLocationSchema'
import VisitReasonForm from '../../../forms/visit-creation/VisitReasonForm'
import NewVisitReasonSchema from '../../../schemas/NewVisitReasonSchema'
import VisitModalityForm from '../../../forms/visit-creation/VisitModalityForm/VisitModalityForm'
import NewVisitModalitySchema from '../../../schemas/NewVisitModalitySchema'
import ProviderSelectionForm from '../../../forms/visit-creation/ProviderSelectionForm/ProviderSelectionForm'
import NewVisitProviderSchema from '../../../schemas/NewVisitProviderSchema'
import TestIds from '../../../util/TestIds'
import SchedulingForm from '../../../forms/visit-creation/SchedulingForm'
import NewVisitSchedulingSchema from '../../../schemas/NewVisitSchedulingSchema'
import PharmacySelectionForm from '../../../forms/visit-creation/PharmacySelectionForm/PharmacySelectionForm'
import NewVisitPharmacySchema from '../../../schemas/NewVisitPharmacySchema'
import PaymentForm from '../../../forms/visit-creation/PaymentForm/PaymentForm'
import NewVisitPaymentSchema from '../../../schemas/NewVisitPaymentSchema'
import VisitConfirmationForm from '../../../forms/visit-creation/VisitConfirmationForm/VisitConfirmationForm'
import NewVisitSchema from '../../../schemas/NewVisitSchema'
import VisitTypes, { getActualVisitType } from '../../../util/visit-types'
import VisitPreferencesForm from '../../../forms/visit-creation/VisitPreferencesForm'
import NewVisitPreferencesSchema from '../../../schemas/NewVisitPreferencesSchema'
import { providerDefaultFilters, normalizeFiltersForApi, filterProvidersByModality } from '../../../util/provider'
import VisitScheduleType from '../../../util/visit-schedule-types'
import { ADD_PENDING_VISIT, DASHBOARD, createAddPendingVisitPath, createWithQuery } from 'routes/paths'
import AngleLeftIcon from '@ui-components-3/icons/lib/regular/AngleLeft'
import AngleRightIcon from '@ui-components-3/icons/lib/regular/AngleRight'
import MemberSelectForm from 'forms/visit-creation/MemberSelectForm'
import NewVisitTypeForm from 'forms/visit-creation/NewVisitTypeForm'
import NewVisitTypeSchema from 'schemas/NewVisitTypeSchema'
import NewVisitCountryForm from 'forms/visit-creation/NewVisitCountryForm'
import NewVisitLocationCountrySchema from 'schemas/NewVisitLocationCountrySchema'
import NewVisitLocationStateSchema from 'schemas/NewVisitLocationStateSchema'
import NewVisitStateForm from 'forms/visit-creation/NewVisitStateForm'
import { useSelector } from 'react-redux'
import * as MemberSelectors from 'redux/selectors/members'
import { getPreferredAddress } from 'util/member'
import { Steps } from 'pages/AddPendingVisitPage/AddPendingVisitPage'
import { restrictedCountries } from 'util/countries'
import { isLocationOutsideUS, isUSLocation } from 'util/visit-location'
import AddServiceForm from 'forms/visit-creation/AddServiceForm'
import NewVisitEntryForm from 'forms/visit-creation/NewVisitRoutingEntryForm'
import { newVisitRoutingEntryCardType } from 'forms/visit-creation/NewVisitRoutingEntryForm/NewVisitRoutingEntryForm'
import NewVisitMedicalAndHCSchema from 'schemas/NewVisitMedicalAndHCSchema'
import VisitQuestionsForm from '../VisitQuestions'
import RecommendedVisitTypeForm from '../RecommendedVisitType'
import useFeatureFlagToggle from 'components/feature-flag/useFeatureFlagToggle'
import { TcFF } from 'components/feature-flag/flags'
import { ContactSupportScene } from '../ContactSupportWrapper/ContactSupportWrappers'
import ContactSupportStep from '../ContactSupportStep/ContactSupportStep'

const INITIAL_VALUES = (pendingVisit = {}, member = {}) => {
  const address = getPreferredAddress(member, 'mailing')

  return {
    [Scenes.newVisit.location]: {
      isOutsideUS: pendingVisit.locationCountry ? String(isLocationOutsideUS(pendingVisit.locationCountry)) : '',
    },
    [Scenes.newVisit.locationState]: {
      location: pendingVisit.location || address?.state || '',
    },
    [Scenes.newVisit.locationCountry]: {
      locationCountry: pendingVisit.locationCountry || '',
    },
    [Scenes.newVisit.visitType]: {
      visitType: getActualVisitType(pendingVisit.visitType, pendingVisit.scheduledType) || '',
      scheduledType: pendingVisit.scheduledType || '',
      price: pendingVisit.price || 0,
      reasonForVisit: '',
    },
    [Scenes.newVisit.reason]: {
      reasonForVisit: pendingVisit.reasonForVisit || '',
    },
    [Scenes.newVisit.preference]: {
      preferences: {
        gender: '',
        ethnicity: [],
        specialty: [],
        languages: [],
      },
    },
    [Scenes.newVisit.modality]: {
      modality: pendingVisit.modality || EVisitModalities.video,
      phone: pendingVisit.phone || null,
      phoneId: pendingVisit.phone ? pendingVisit.phone.id : '',
      aslInterpreterNeeded: pendingVisit.aslInterpreterNeeded ?? false,
    },
    [Scenes.newVisit.providerList]: {
      providerId: pendingVisit.providerId || '',
      providerName: pendingVisit.providerName || undefined,
      preferences: pendingVisit.preferences || null,
      filters: pendingVisit.filters || providerDefaultFilters(pendingVisit),
    },
    [Scenes.newVisit.scheduling]: {
      timezone: pendingVisit.timezone || '',
      scheduledAt: pendingVisit.scheduledAt || null,
    },
    [Scenes.newVisit.pharmacy]: {
      pharmacy: pendingVisit.pharmacy || {
        externalId: '',
        name: '',
      },
    },
    [Scenes.newVisit.payment]: {
      paymentTransactionId: pendingVisit.paymentTransactionId || '',
    },
    [Scenes.newVisit.confirmation]: {
      ...pendingVisit,
    },
  }
}

const AddPendingVisitSceneChooser = (props) => {
  const {
    scene,
    pendingVisit = {},
    onSubmit,
    onCancel,
    clearPayload,
    checkProviderAvailability,
    fetchAvailableProviders,
    query,
    isServiceLineRoutingFlagOn,
    isMinorConsentFlagOn,
    isUserMinor,
    updatePayload,
    navigateHome,
  } = props
  const history = useHistory()
  const member = useSelector(MemberSelectors.getMember(pendingVisit?.memberId))
  const step = useMemo(() => {
    const step = Number(query?.step)
    if (Number.isNaN(step)) return Steps.memberSelect
    return step
  }, [query?.step])
  const isPharmacyReqiured = (visitType) => visitType === VisitTypes.psychiatry || visitType === VisitTypes.medical
  const cardType = useMemo(() => query?.cardType, [query?.cardType])
  const throughStep = useMemo(() => query?.throughStep, [query?.throughStep])
  const isRestrictedCountryQuery = useMemo(() => query?.isRestrictedCountry, [query?.isRestrictedCountry])
  const isAllowDependentsFlagOn = useFeatureFlagToggle(TcFF.tcAllowDependents)

  const initialValues = useMemo(() => {
    return INITIAL_VALUES(pendingVisit, member)[scene]
  }, [pendingVisit, member, scene])

  const defaultFilters = providerDefaultFilters(pendingVisit)

  const isCountryRestricted = useMemo(
    () => !!pendingVisit.locationCountry && restrictedCountries.includes(pendingVisit.locationCountry),
    [pendingVisit.locationCountry],
  )

  const handleReturnToDashboard = useCallback(() => {
    clearPayload()
    history.push(DASHBOARD)
  }, [clearPayload, history])

  const cleanPhoneIdSubmission = useCallback(
    async (values, form) => {
      const clean = omit(values, ['phoneId'])
      if (pendingVisit.scheduledType === VisitScheduleType.scheduled) {
        const pendingValues = {
          ...pendingVisit,
          modality: values.modality,
        }
        const success = await ensureProvidersExist(pendingValues)
        if (!success) return
      }
      return onSubmit(clean, form)
    },
    [onSubmit],
  )

  const handleNoProvidersAvailable = useCallback(() => {
    history.push(
      createWithQuery(ADD_PENDING_VISIT, {
        step: Steps.noAvailableProviders, // noAvailableProviders step
      }),
    )
  }, [])

  const ensureProvidersExist = useCallback(
    async (data) => {
      const { location, visitType, scheduledType, modality } = data
      if (scheduledType === VisitScheduleType.now) {
        const { success, count } = await checkProviderAvailability(location, visitType)
        if (!success || count === 0) {
          handleNoProvidersAvailable()
          return false
        }
      } else {
        const filters = normalizeFiltersForApi(defaultFilters)
        const { success, data } = await fetchAvailableProviders(filters)
        if (success) {
          const filteredProviders = filterProvidersByModality(data, modality)
          if (!filteredProviders.length) {
            handleNoProvidersAvailable()
            return false
          }
        }
      }
      return true
    },
    [checkProviderAvailability, fetchAvailableProviders],
  )

  const handleVisitStateSubmit = useCallback(
    async (values, form) => {
      const clone = { ...values }
      if (pendingVisit.scheduledType === VisitScheduleType.now && isServiceLineRoutingFlagOn) {
        const success = await ensureProvidersExist({ ...pendingVisit, ...values })
        if (!success) return
      }
      return onSubmit(clone, form)
    },
    [onSubmit, pendingVisit],
  )

  const cleanVisitTypeSubmission = useCallback(
    async (values, form) => {
      const clone = { ...values, location: pendingVisit.location }
      if (values.visitType === 'medical_now') {
        clone.visitType = VisitTypes.medical
      }
      if (values.visitType === VisitTypes.therapyNow) {
        clone.visitType = VisitTypes.therapy
      }
      // TODO ensure providers exist (on queued visits)
      if (clone.scheduledType === VisitScheduleType.now && !isServiceLineRoutingFlagOn) {
        const success = await ensureProvidersExist(clone)
        if (!success) return
      }
      return onSubmit(clone, form)
    },
    [onSubmit, initialValues, pendingVisit],
  )

  const isScheduledType = pendingVisit.scheduledType === VisitScheduleType.scheduled
  const schedulingOrModalityStep = isScheduledType ? Steps.scheduling : Steps.modality
  const isVisitPharmacyRequired = isPharmacyReqiured(pendingVisit.visitType)
  const visitTypeStepBack = useMemo(() => {
    if (cardType && !throughStep && !isRestrictedCountryQuery) {
      return Steps.routingEntry
    }
    if (throughStep && !isRestrictedCountryQuery) {
      return throughStep
    }
    return isUSLocation(pendingVisit.locationCountry) ? Steps.locationState : Steps.locationCountry
  }, [cardType, pendingVisit.locationCountry, throughStep, isRestrictedCountryQuery])

  const visitReasonStepBack = useMemo(() => {
    return isUSLocation(pendingVisit.locationCountry) ? Steps.locationState : Steps.locationCountry
  }, [pendingVisit.locationCountry])

  const memberSelectStepBack = useMemo(() => {
    if (cardType || throughStep) {
      if (cardType === newVisitRoutingEntryCardType.mentalHealth) {
        return Steps.recommended
      }
      return Steps.visitType
    }
    if (isServiceLineRoutingFlagOn) {
      return Steps.routingEntry
    }
  }, [isServiceLineRoutingFlagOn, cardType])

  const stepBackRules = {
    [Steps.locationCountry]: () => Steps.location,
    [Steps.visitType]: () => visitTypeStepBack,
    [Steps.pharmacy]: () => schedulingOrModalityStep,
    [Steps.payment]: () => {
      if (isVisitPharmacyRequired) {
        return Steps.pharmacy
      }
      return schedulingOrModalityStep
    },
    [Steps.confirmation]: () => {
      if (pendingVisit.price) {
        return Steps.payment
      } else if (isVisitPharmacyRequired) {
        return Steps.pharmacy
      }
      return schedulingOrModalityStep
    },
    [Steps.memberSelect]: () => memberSelectStepBack,
    [Steps.reason]: () => (isServiceLineRoutingFlagOn ? visitReasonStepBack : step - 1),
    [Steps.location]: () => {
      if (isServiceLineRoutingFlagOn && !isAllowDependentsFlagOn) {
        return memberSelectStepBack
      }
      return step - 1
    },
    [Steps.callSupport]: () => {
      if (!isServiceLineRoutingFlagOn && isMinorConsentFlagOn && isUserMinor) return Steps.visitType
      return step - 1
    },
  }

  const handleBackCleanup = useCallback(async () => {
    if (!isServiceLineRoutingFlagOn) {
      return
    }
    const clone = { ...pendingVisit }
    const initialValues = INITIAL_VALUES()[scene]
    Object.keys(initialValues).forEach((key) => {
      if (
        (scene === Scenes.newVisit.visitType && isRestrictedCountryQuery) ||
        scene === Scenes.newVisit.locationCountry
      ) {
        return
      }
      if (scene === Scenes.newVisit.location) {
        delete clone.locationCountry
        return
      }
      delete clone[key]
    })
    await updatePayload(clone, true)
  }, [pendingVisit, scene, isServiceLineRoutingFlagOn, isRestrictedCountryQuery])

  const handleBackClick = () => {
    const newPath = createAddPendingVisitPath(ADD_PENDING_VISIT)
    // If step has a special rule follow that, otherwise default to step -1
    let targetStep = stepBackRules[step] ? stepBackRules[step]() : Math.max(0, step - 1)
    const params = {
      step: targetStep,
    }
    if (cardType) {
      params.cardType = cardType
    }
    if (throughStep) {
      params.throughStep = Steps.visitType
    }
    if (targetStep == Steps.visitType && scene === Scenes.newVisit.visitType) {
      if (throughStep) {
        params.step = Steps.visitType
        params.cardType = newVisitRoutingEntryCardType.medicalAndHC
        delete params.throughStep
      }
      handleBackCleanup()
      return history.push(createWithQuery(newPath, params))
    }
    handleBackCleanup()
    history.push(createWithQuery(newPath, { ...params }))
  }

  const showBackButton = useMemo(() => {
    if (scene === Scenes.newVisit.engineEntry) return false
    if (scene === Scenes.newVisit.addService) return false
    if (scene === Scenes.newVisit.location && !isAllowDependentsFlagOn && !isServiceLineRoutingFlagOn) return false
    if (scene === Scenes.newVisit.memberSelect) {
      return isServiceLineRoutingFlagOn
    }
    return true
  }, [scene, isServiceLineRoutingFlagOn, isAllowDependentsFlagOn])

  const renderActions = ({ disabled, submitText = 'Next', leaveFlow = false }) => (
    <div className="fixed bottom-0 left-5 right-5 z-10 -mx-5 mt-auto flex h-[72px] items-center justify-between gap-3 border-t border-t-neutral-300 bg-white px-5 sm:min-h-[100px] sm:py-4 md:flex-row md:items-center lg:left-[290px] lg:right-[40px] lg:-mx-10">
      <button
        data-testid={TestIds.newVisit.button.cancel}
        className="btn btn-fuchsia-outlined sm:px-s h-10 rounded-[10px] px-3 sm:h-14 sm:min-w-[120px] sm:rounded-2xl md:mb-0 lg:min-w-[180px]"
        aria-label={'Cancel Visit'}
        onClick={onCancel}
        type="button"
      >
        Cancel
      </button>
      <div className="flex items-center justify-between gap-2 sm:gap-6">
        {showBackButton && (
          <button
            className="btn btn-neutral-outlined h-10 flex-1 rounded-[10px] px-3 sm:h-14 sm:rounded-2xl sm:px-4 md:mb-0 lg:min-w-[180px]"
            data-testid={TestIds.newVisit.button.back}
            onClick={handleBackClick}
            type="button"
          >
            <AngleLeftIcon className="h-5 w-5 sm:h-6 sm:w-6" aria-hidden="true" />
            Back
          </button>
        )}
        {scene === Scenes.newVisit.addService ? null : !leaveFlow ? (
          <button
            className="btn btn-primary sm:px-s h-10 flex-1 rounded-[10px] px-3 sm:h-14 sm:min-w-[120px] sm:rounded-2xl md:mb-0 lg:min-w-[180px]"
            type="submit"
            disabled={disabled}
            data-testid={TestIds.newVisit.button.next}
          >
            {submitText}
            <AngleRightIcon className="h-5 w-5 sm:h-6 sm:w-6" aria-hidden="true" />
          </button>
        ) : (
          <button
            onClick={navigateHome}
            className="btn btn-primary h-10 min-w-[120px] flex-1 rounded-[10px] px-3 sm:h-14 sm:rounded-2xl md:mb-0 lg:min-w-[180px]"
            data-testid={TestIds.newVisit.button.back}
          >
            Done
          </button>
        )}
      </div>
    </div>
  )

  switch (scene) {
    case Scenes.newVisit.addService:
      return <AddServiceForm onSubmit={onSubmit}>{renderActions}</AddServiceForm>
    case Scenes.newVisit.memberSelect:
      return (
        <MemberSelectForm onSubmit={onSubmit} pendingVisit={pendingVisit}>
          {renderActions}
        </MemberSelectForm>
      )
    case Scenes.newVisit.location:
      return (
        <NewVisitLocationForm
          {...props}
          onSubmit={onSubmit}
          schema={NewVisitLocationSchema}
          initialValues={initialValues}
          clearPayload={clearPayload}
          isRequiredLabelVisible={false}
          pendingVisit={pendingVisit}
          validateOnMount
        >
          {renderActions}
        </NewVisitLocationForm>
      )
    case Scenes.newVisit.locationState:
      return (
        <NewVisitStateForm
          onSubmit={handleVisitStateSubmit}
          schema={NewVisitLocationStateSchema}
          initialValues={initialValues}
          clearPayload={clearPayload}
          isRequiredLabelVisible={false}
          pendingVisit={pendingVisit}
          validateOnMount
        >
          {renderActions}
        </NewVisitStateForm>
      )
    case Scenes.newVisit.locationCountry:
      return (
        <NewVisitCountryForm
          onSubmit={onSubmit}
          schema={NewVisitLocationCountrySchema}
          initialValues={initialValues}
          clearPayload={clearPayload}
          isRequiredLabelVisible={false}
          pendingVisit={pendingVisit}
          validateOnMount
        >
          {renderActions}
        </NewVisitCountryForm>
      )
    case Scenes.newVisit.visitType:
      return (
        <NewVisitTypeForm
          {...props}
          onSubmit={cleanVisitTypeSubmission}
          schema={cardType ? NewVisitMedicalAndHCSchema : NewVisitTypeSchema}
          initialValues={initialValues}
          clearPayload={clearPayload}
          requiredFields={['Visit Type']}
          isRequiredLabelVisible={false}
          isCountryRestricted={isCountryRestricted}
          pendingVisit={pendingVisit}
          variant={cardType && 'medicalAndHCServices'}
          handleReturnToDashboard={handleReturnToDashboard}
        >
          {renderActions}
        </NewVisitTypeForm>
      )
    case Scenes.newVisit.reason:
      return (
        <VisitReasonForm
          {...props}
          schema={NewVisitReasonSchema}
          initialValues={initialValues}
          isRequiredLabelVisible={true}
          requiredLabelIgnoreFieldsLength
        >
          {renderActions}
        </VisitReasonForm>
      )
    case Scenes.newVisit.preference:
      return (
        <VisitPreferencesForm {...props} schema={NewVisitPreferencesSchema} initialValues={initialValues}>
          {renderActions}
        </VisitPreferencesForm>
      )
    case Scenes.newVisit.modality: {
      return (
        <VisitModalityForm
          {...props}
          validateOnMount
          onSubmit={cleanPhoneIdSubmission}
          memberId={pendingVisit.memberId}
          schema={NewVisitModalitySchema}
          initialValues={initialValues}
          requiredFields={['Visit Type', 'Phone Number']}
          isRequiredLabelVisible={true}
        >
          {renderActions}
        </VisitModalityForm>
      )
    }
    case Scenes.newVisit.providerList: {
      return (
        <ProviderSelectionForm
          {...props}
          schema={NewVisitProviderSchema}
          initialValues={initialValues}
          isRequiredLabelVisible={false}
        >
          {renderActions}
        </ProviderSelectionForm>
      )
    }
    case Scenes.newVisit.scheduling: {
      return (
        <SchedulingForm
          {...props}
          schema={NewVisitSchedulingSchema}
          initialValues={initialValues}
          isRequiredLabelVisible={false}
        >
          {renderActions}
        </SchedulingForm>
      )
    }
    case Scenes.newVisit.pharmacy: {
      return (
        <PharmacySelectionForm
          {...props}
          schema={NewVisitPharmacySchema}
          initialValues={initialValues}
          isRequiredLabelVisible={false}
        >
          {renderActions}
        </PharmacySelectionForm>
      )
    }
    case Scenes.newVisit.payment: {
      return (
        <PaymentForm
          {...props}
          schema={NewVisitPaymentSchema}
          initialValues={initialValues}
          isRequiredLabelVisible={false}
          onBack={handleBackClick}
        />
      )
    }
    case Scenes.newVisit.confirmation: {
      return (
        <VisitConfirmationForm
          {...props}
          schema={NewVisitSchema}
          initialValues={initialValues}
          isRequiredLabelVisible={false}
        >
          {renderActions}
        </VisitConfirmationForm>
      )
    }
    case Scenes.newVisit.engineEntry: {
      return (
        <NewVisitEntryForm onSubmit={onSubmit} handleReturnToDashboard={handleReturnToDashboard}>
          {renderActions}
        </NewVisitEntryForm>
      )
    }
    case Scenes.newVisit.visitQuestions: {
      return <VisitQuestionsForm onSubmit={onSubmit}>{renderActions}</VisitQuestionsForm>
    }
    case Scenes.newVisit.recommended: {
      return (
        <RecommendedVisitTypeForm onSubmit={onSubmit} handleReturnToDashboard={handleReturnToDashboard}>
          {renderActions}
        </RecommendedVisitTypeForm>
      )
    }

    case Scenes.newVisit.callSupport: {
      return <ContactSupportStep>{renderActions}</ContactSupportStep>
    }
    default: {
      return null
    }
  }
}

AddPendingVisitSceneChooser.propTypes = {
  pendingVisit: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  scene: PropTypes.oneOf(Object.values(Scenes.newVisit)),
  isLoading: PropTypes.bool,
  clearPayload: PropTypes.func,
  checkProviderAvailability: PropTypes.func.isRequired,
  fetchAvailableProviders: PropTypes.func.isRequired,
}

export default AddPendingVisitSceneChooser
